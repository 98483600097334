<template>
  <div>
    <b-modal id="editProductModal" title="Ürün Düzenle" hide-footer size="lg" @show="getGames">
      <b-form @submit="onSubmit">
        <div class="row">
          <div class="col-md-6">
            <b-form-group
                label="Ürün Adı">
              <b-input placeholder="" v-model="title"></b-input>
            </b-form-group>
            <b-form-group
                label="Ürün Fiyatı">
              <b-input v-model="price" type="number" step="0.10"></b-input>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group
                label="Ekstra Data">
              <b-input v-model="extra_data" ></b-input>
              <b-alert show="" variant="primary">
                Ürün satıldığında, ödeme onay adresinize (notify_url) bu bilgi gönderilecektir,
                EP miktarı, kredi tutarı 10, 20 vb. kullanılabilir.
              </b-alert>
            </b-form-group>
          </div>
        </div>
        <b-form-group
            label="Ürün hakkında kısa bir açıklama:">
          <b-textarea v-model="description"></b-textarea>
        </b-form-group>

        <div class="d-flex justify-content-center" v-if="!loading">
          <b-button type="submit" variant="success" class=""> <i class="fas fa-check" /> Kaydet </b-button>
        </div>
        <div class="d-flex justify-content-center" v-if="loading">
          <b-button type="submit" variant="success" class="" disabled> <span>  <i class="fas fa-spinner fa-spin"></i></span> </b-button>
        </div>
      </b-form>

    </b-modal>
  </div>
</template>

<script>
import apiService from "@/services/apiService";

export default {
  name: "EditProduct",
  data() {
    return {
      loading: false,
      title: '',
      description: '',
      price: 0,
      games: [],
      extra_data: '',
    }
  },
  props: ['id','game_id'],
  methods: {
    getGames() {
      this.loading = false
      this.productDetail()
      apiService.getGames()
          .then((res) => {
            if(res.state){
              res.data.map((game) => {
                if(game.status){
                  this.games.push({label: game.title, value: game.id})
                }
              })
            }
          })
    },
    errMsg(msg) {
      this.$swal({
        showConfirmButton: true,
        icon: "error",
        title: "Hata",
        text: msg,
        confirmButtonText: 'OK',
        confirmButtonColor: '#3085d6',
      });
    },
    successMsg(msg) {
      this.$swal({
        showConfirmButton: true,
        icon: "success",
        title: "Tebrikler!",
        text: msg,
        confirmButtonText: 'OK',
        confirmButtonColor: '#3085d6',
      });

    },
    onSubmit(event) {
      event.preventDefault()
      this.loading = true
      apiService.editProduct({title: this.title, description: this.description, extra_data: this.extra_data, price: this.price, game_id: this.game_id, id: this.id})
      .then((res) => {
        this.loading = false
        if(res.state){
          this.title = ''
          this.web_site = ''
          this.price = ''
          this.game_id = ''
          this.description  = ''
          this.successMsg('Ürün başarıyla düzenlendi.');
          this.$store.dispatch('getRefresh', {products: true})
          this.$bvModal.hide('editProductModal')

        }else{
          this.errMsg(res.message)
        }
      })
    },
    productDetail() {
     if(this.id != 0){
       apiService.productDetail({id: this.id, game_id: this.game_id})
       .then((res) => {
         if(res.state){
           this.title = res.data[0].title
           this.description = res.data[0].description
           this.price = res.data[0].price
           this.extra_data = res.data[0].extra_data
         }else{
           this.errMsg(res.message)
         }
       })
     }
    }
  },
  watch: {
    id: function () {
      this.productDetail()
    }
  }

}
</script>

<style scoped>

</style>