<template>
  <div class="wrapper">
    <Menu></Menu>
    <div id="content-page" class="content-page">
      <div class="container-fluid">

        <div class="row">
          <div class="col-sm-12">
            <div class="iq-card">
              <div class="iq-card-header d-flex justify-content-between">
                <div class="iq-header-title">
                  <h4 class="card-title">Kampanya Oluştur</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
              <div class="iq-card">
                <div class="iq-card-body ">
                  <div class="row">
                    <div class="col-md-12">
                      <label for="">Kampanya Başlığı</label>
                      <b-input v-model="title"></b-input>
                    </div>
                    <div class="col-md-6">
                      <b-form-group>
                        <label>
                          Kampanya başlangıç tarihi
                          <b-badge variant="info"> {{ startDate }} {{ startTime }}</b-badge>
                        </label>
                        <div class="row">
                          <div class="col-md-8">
                            <b-form-datepicker  v-model="startDate" class="mb-2"></b-form-datepicker>
                          </div>
                          <div class="col-md-4">
                            <b-form-timepicker v-model="startTime" locale="en"></b-form-timepicker>
                          </div>
                        </div>

                      </b-form-group>
                    </div>
                    <div class="col-md-6">
                      <b-form-group>
                        <label>
                          Kampanya bitiş tarihi
                          <b-badge variant="info"> {{ endDate }} {{ endTime }}</b-badge>
                        </label>
                        <div class="row">
                          <div class="col-md-8">
                            <b-form-datepicker  v-model="endDate" class="mb-2"></b-form-datepicker>
                          </div>
                          <div class="col-md-4">
                            <b-form-timepicker v-model="endTime" locale="en"></b-form-timepicker>
                          </div>
                        </div>

                      </b-form-group>
                    </div>
                    <div class="col-md-6">
                      <b-form-group
                          label="Mağaza seçiniz">
                        <v-select
                            v-model="game_id"
                            class="form-control"
                            :options="games"

                        ></v-select>
                      </b-form-group>
                    </div>
                    <div class="col-md-6">
                      <b-form-group
                          label="Kampanyada geçerli ödeme kanalları"
                          label-for="input-1"
                      >
                        <v-select
                            multiple
                            v-model="payment_id"
                            class="form-control"
                            :options="payment_channels"
                            required
                        ></v-select>
                      </b-form-group>
                    </div>
                    <div class="col-md-6" v-if="game_id.value">
                      <b-card-header> Mağazaya ait ürünler</b-card-header>
                      <b-table
                          :items="products"
                          :fields="productsFields"
                          responsive="sm"
                          :busy="loading"
                          bordered
                      >
                        <template v-slot:table-busy>
                          <div class="text-center text-primary my-2">
                            <b-spinner class="align-middle"></b-spinner>
                            <strong>Yükleniyor..</strong>
                          </div>
                        </template>
                        <template v-slot:cell(price)="data">
                          {{data.item.price | currency}} TRY
                        </template>
                        <template v-slot:cell(edit)="data">
                          <b-button @click="addProduct(data.item.id)" variant="success" class="btn btn-sm" v-b-tooltip:v-b-hover title="Kampanya listesine ekle">
                            <i class="fas fa-arrow-right"></i>
                          </b-button>
                        </template>
                      </b-table>

                    </div>
                    <div class="col-md-6" v-if="game_id.value">
                      <b-card-header> Kampanya dahilindeki ürünler</b-card-header>
                      <b-table
                          :items="selectedProducts"
                          :fields="productsFields"
                          responsive="sm"
                          :busy="loading"
                          bordered
                      >
                        <template v-slot:table-busy>
                          <div class="text-center text-primary my-2">
                            <b-spinner class="align-middle"></b-spinner>
                            <strong>Yükleniyor..</strong>
                          </div>
                        </template>
                        <template v-slot:cell(price)="data">
                          <b-input type="numeric" step="0.01" v-model="selectedProducts[data.index].price">
                          </b-input>
                        </template>
                        <template v-slot:cell(extra_data)="data">
                          <b-input v-model="selectedProducts[data.index].extra_data">
                          </b-input>
                        </template>
                        <template v-slot:cell(edit)="data">
                          <b-button @click="removeProduct(data.item.id)" variant="danger" class="btn btn-sm" v-b-tooltip:v-b-hover title="Kampanya listesinden çıkar">
                            <i class="fas fa-arrow-left"></i>
                          </b-button>
                        </template>
                      </b-table>

                    </div>
                    <div class="col-md-12">
                      <div class="d-flex justify-content-center" v-if="!loading">
                        <b-button @click="addCampaign" variant="success" class=""> <i class="fas fa-check" /> Oluştur </b-button>
                      </div>
                      <div class="d-flex justify-content-center" v-if="loading">
                        <b-button variant="success" class="" disabled> <span>  <i class="fas fa-spinner fa-spin"></i></span> </b-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Menu from "@/components/Menu";
import apiService from "@/services/apiService";
export default {
  name: "AddCampaign",
  data() {
    return {
      loading: false,
      startDate: '',
      endDate: '',
      startTime: '',
      endTime: '',
      game_id: {
        label: null,
        value: null
      },
      games: [],
      payment_channels: [],
      payment_id: '',
      products: [],
      selectedProducts: [],
      productsFields: [
        {key: 'title', label: 'Ürün Adı'},
        {key: 'price', label: 'Ürün Fiyatı'},
        {key: 'extra_data', label: 'Ekstra Data'},
        {key: 'edit', label: '#'},
      ],
      title: ''

    }
  },
  components: {
    Menu,
  },
  methods: {
    getGames() {
      this.loading = false
      apiService.getGames()
          .then((res) => {
            if(res.state){
              res.data.map((game) => {
                if(game.status){
                  this.games.push({label: game.title, value: game.id})
                }
              })
            }
          })
    },
    getPaymentChannels() {
      apiService.paymentChannels()
          .then((res) => {
            res.data.map((channel) => {
              this.payment_channels.push({label: channel.title, value: channel.id})
            })

          })
    },
    errMsg(msg) {
      this.$swal({
        showConfirmButton: true,
        icon: "error",
        title: "Hata",
        text: msg,
        confirmButtonText: 'OK',
        confirmButtonColor: '#3085d6',
      });

    },
    successMsg(msg) {
      this.$swal({
        showConfirmButton: true,
        icon: "success",
        title: "Tebrikler!",
        text: msg,
        confirmButtonText: 'OK',
        confirmButtonColor: '#3085d6',
      });

    },
    getProducts(){
      this.loading = true
      this.products = []
      this.selectedProducts = []
      apiService.getProducts({game_id: [this.game_id], product_name: ''})
          .then((res) => {
            this.loading = false
            if(res.state){
              this.products = res.data
            }else{
              this.errMsg(res.message)
            }
          })
    },
    addProduct(index) {
      let findIndex = this.products.findIndex(e => e.id === index)
      this.selectedProducts.unshift(this.products[findIndex])
      this.products.splice(findIndex, 1)
    },
    removeProduct(index) {
      let findIndex = this.selectedProducts.findIndex(e => e.id === index)
      this.products.unshift(this.selectedProducts[findIndex])
      this.selectedProducts.splice(findIndex, 1)
    },
    addCampaign() {
      this.loading = true
      apiService.addCampaign({title: this.title, game_id: this.game_id, startDate: this.startDate, endDate: this.endDate, payment_id: this.payment_id, products: this.selectedProducts, startTime: this.startTime, endTime: this.endTime})
      .then((res) => {
        this.loading = false
        if(res.state){
          this.successMsg("Kampanya başarıyla oluşturuldu.")
          this.$router.push("/campaigns")
        }else{
          this.errMsg(res.message)
        }
      })
    }
  },
  created() {
    this.getGames()
    this.getPaymentChannels()
  },
  watch: {
    game_id: function () {
      if(this.game_id.value){
        this.getProducts()
      }
    }
  }
}
</script>

<style scoped>

</style>