<template>
  <div class="wrapper">
    <Menu></Menu>
    <!-- Page Content  -->
    <div id="content-page" class="content-page">
      <div class="container-fluid">
        <div class="row">
          <div class="col-xl-3">
            <div class="iq-card iq-card-block iq-card-stretch">
              <div class="iq-card-body">
                <h2 class="mb-0" v-if="loading">
                  <b-skeleton></b-skeleton>
                </h2>
                <h2 class="mb-0" v-else>
                  <span class="counter">{{ balance  }}</span> <span>TL</span>
                  &nbsp; <i class="fa fa-refresh text-primary" @click="refresh"></i>
                </h2>
                <p class="mb-0" v-if="!loading">Bakiyeniz </p>
                <b-button class="btn btn-danger d-block " v-if="!loading" v-b-modal.withdrawalModal> Para çek</b-button>
              </div>
            </div>
          </div>
          <div class="col-xl-6">
            <div class="row">
              <div class="col-sm-12">
                <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
                  <div class="iq-card-body">
                    <div class="row">
                      <div class="col-md-6 col-lg-4">
                        <div class="d-flex align-items-center mb-3 mb-lg-0">
                          <div class="rounded-circle iq-card-icon iq-bg-primary mr-3"> <i class="fas fa-money"></i></div>
                          <div class="text-left">
                            <h4 v-if="loading"><b-skeleton></b-skeleton></h4>
                            <h4 v-else> {{ todaySalesPrice | currency }} TL</h4>
                            <p class="mb-0">Bugün</p>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-lg-4">
                        <div class="d-flex align-items-center mb-3 mb-lg-0">
                          <div class="rounded-circle iq-card-icon iq-bg-info mr-3"> <i class="fas fa-money"></i></div>
                          <div class="text-left">
                            <h4 v-if="loading"><b-skeleton></b-skeleton></h4>
                            <h4 v-else> {{ thisWeekSalesPrice | currency }} TL</h4>
                            <p class="mb-0">Bu hafta</p>
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 col-lg-4">
                        <div class="d-flex align-items-center mb-3 mb-md-0">
                          <div class="rounded-circle iq-card-icon iq-bg-danger mr-3"> <i class="fas fa-money"></i></div>
                          <div class="text-left">
                            <h4 v-if="loading"><b-skeleton></b-skeleton></h4>
                            <h4 v-else> {{ thisMonthSalesPrice | currency }} TL</h4>
                            <p class="mb-0">Bu Ay</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3">
            <div class="iq-right-fixed rounded iq-card iq-card iq-card-block iq-card-stretch iq-card-height">
              <div class="iq-card-header d-flex justify-content-between">
                <div class="iq-header-title">
                  <h4 class="card-title">Telegram Bildirimleri</h4>
                </div>
              </div>
              <div class="iq-card-body">
                <code>
                  Telegram üzerinden <a href="https://t.me/kasagame_bot" target="_blank">@kasagame_bot</a> hesabına yazarak veya gruba ekleyerek mesaj kısmına "/start" yazıp gönderin.
                  <br>
                  Botun sizinle paylaştığı chatID aşağı yapıştırın.
                </code>
                <b-form-group>
                  <b-input-group label="ChatID:">
                    <b-input v-model="chat_id"></b-input>
                    <b-button @click="updateChatID"> Kaydet </b-button>
                  </b-input-group>
                </b-form-group>
              </div>
            </div>
          </div>
          <div class="col-lg-12">
            <div class="iq-card iq-card-block iq-card-stretch iq-card-height">
              <div class="iq-card-header d-flex justify-content-between">
                <div class="iq-header-title">
                  <h4 class="card-title">Son başarılı satışlar </h4>
                </div>
              </div>
              <div class="iq-card-body">
                <table class="table mb-0 table-borderless">
                  <thead>
                  <tr>
                    <th scope="col">Mağaza</th>
                    <th scope="col">Ürün</th>
                    <th scope="col">Referans ID</th>
                    <th scope="col">Tutar</th>
                    <th scope="col">Kazanç</th>
                    <th scope="col">Tarih</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-if="loading">
                    <td><b-skeleton></b-skeleton></td>
                    <td><b-skeleton></b-skeleton></td>
                    <td><b-skeleton></b-skeleton></td>
                    <td><b-skeleton></b-skeleton></td>
                    <td><b-skeleton></b-skeleton></td>
                  </tr>

                  <tr v-for="(item, index) in orders" :key="index">
                    <td>{{ item.game.title }}</td>
                    <td>{{ item.product.title }}</td>
                    <td>{{ item.return_id }}</td>
                    <td>{{ item.price | currency }} TL</td>
                    <td>{{ item.merchant_gain | currency}} TL</td>
                    <td>{{ item.sale_date | formatDate }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <withdrawal></withdrawal>
  </div>

</template>

<script>
import Menu from "@/components/Menu";
import apiService from "@/services/apiService";
import Withdrawal from "@/components/Modals/Withdrawal";

import {store} from "@/store";
export default {
  name: "Dashboard",
  data() {
    return {
      balance: this.$store.getters.user.user.earning_balance,
      loading: true,
      withdrawns: [],
      orders: [],
      todaySalesPrice: 0,
      thisWeekSalesPrice: 0,
      thisMonthSalesPrice: 0,
      news: [],
      chat_id: this.$store.getters.user.user.chat_id
    }
  },
  methods: {
    refresh() {
      this.getStatics()
      this.getBalance()
    },
    getStatics() {
      this.loading = true
      this.withdrawns = []
      this.orders = []
      this.todaySalesPrice = 0
      this.thisWeekSalesPrice = 0
      this.thisMonthSalesPrice = 0
      this.news = []
      apiService.getStatics()
      .then((res) => {
        if(res.state){
          this.loading = false
          this.withdrawns = res.withdrawns
          this.orders = res.orders
          this.todaySalesPrice = res.todaySalesPrice
          this.thisWeekSalesPrice = res.thisWeekSalesPrice
          this.thisMonthSalesPrice = res.thisMonthSalesPrice
          this.news = res.news
        }
      })
    },
    getBalance() {
      apiService.getToken()
      .then((res) => {
        if(res.state){
          store.dispatch('updateUser', {user: res.user, token: res.token})
          this.balance = res.user.earning_balance
        }else{
          store.dispatch('updateUser', {token: ""})
          this.$router.push({path: '/login'})
        }
      })
    },
    errMsg(msg) {
      this.$swal({
        showConfirmButton: true,
        icon: "error",
        title: "Hata",
        text: msg,
        confirmButtonText: 'OK',
        confirmButtonColor: '#3085d6',
      });

    },
    successMsg(msg) {
      this.$swal({
        showConfirmButton: true,
        icon: "success",
        title: "Tebrikler!",
        text: msg,
        confirmButtonText: 'OK',
        confirmButtonColor: '#3085d6',
      });

    },
    updateChatID() {
      apiService.editchatId({chat_id: this.chat_id})
      .then(() => {
        this.successMsg('ChatId güncellendi.')
      })
    }
  },
  components: {
    Menu, Withdrawal
  },
  created() {
    this.getStatics()
  }
}
</script>

<style scoped>

</style>