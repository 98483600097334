import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export const store = new Vuex.Store({
    plugins: [createPersistedState()],
    state: {
        user: {
            token: ''
        },
        refresh: {},
    },
    mutations: {
        commitUser (state, data) {
            state.user = data
        },
        setRefresh(state, payLoad){
            state.refresh = payLoad
        },

    },
    actions: {
        updateUser (context, payload) {
            context.commit('commitUser', payload)
        },
        getRefresh(context, payLoad) {
            context.commit('setRefresh', payLoad)
        },
    },
    getters: {
        user: state => state.user,
        getRefresh: state => state.refresh,

    },
})
