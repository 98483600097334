<template>
  <div class="iq-top-navbar">
    <div class="iq-navbar-custom d-flex align-items-center justify-content-between">
      <div class="iq-sidebar-logo">
        <div class="top-logo">
          <router-link :to="{path: '/dashboard'}" class="logo">
            <img :src="require('../assets/images/kasagame-logo.svg')" class="img-fluid" alt="">
          </router-link>

        </div>
      </div>
      <div class="iq-menu-horizontal">
        <nav class="iq-sidebar-menu">
          <ul id="iq-sidebar-toggle" class="iq-menu d-flex">
            <li :class="{'active' : address === '/dashboard'}">
              <router-link :to="{path: '/dashboard'}" class="iq-waves-effect collapsed">
                <i class="ri-home-4-line"></i>
                <span>Anasayfa</span>
              </router-link>
            </li>
            <li :class="{'active' : address === '/stores'}">
              <router-link :to="{path: '/stores'}" class="iq-waves-effect collapsed">
                <i class="ri-store-2-fill"></i>
                <span>Mağazalar</span>
              </router-link>
            </li>
            <li :class="{'active' : address === '/products'}">
              <router-link :to="{path: '/products'}" class="iq-waves-effect collapsed">
                <i class="ri-shopping-cart-line"></i>
                <span>Ürünler</span>
              </router-link>
            </li>
            <li :class="{'active' : address === '/campaigns'}">
              <router-link :to="{path: '/campaigns'}" class="iq-waves-effect collapsed">
                <i class="fas fa-gift"></i>
                <span>Kampanyalar</span>
              </router-link>
            </li>
            <li :class="{'active' : address === '/reports'}">
              <router-link :to="{path: '/reports'}" class="iq-waves-effect collapsed">
                <i class="ri-currency-fill"></i>
                <span>Satış Raporları</span>
              </router-link>
            </li>
            <li :class="{'active' : address === '/balance'}">
              <router-link :to="{path: '/balance'}" class="iq-waves-effect collapsed">
                <i class="ri-wallet-2-fill"></i>
                <span>Bakiye & Ödeme Geçmişi</span>
              </router-link>
            </li>
            <li class="">
              <router-link :to="{path: '/logout'}" class="iq-waves-effect collapsed">
                <i class="ri-logout-circle-fill"></i>
                <span class="text-danger">Güvenli Çıkış</span>
              </router-link>
            </li>

          </ul>
        </nav>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "Menu",
  data() {
    return {
      address: this.$route.path
    }
  }
}
</script>

<style scoped>

</style>