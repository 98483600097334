<template>
  <div>
    <b-modal id="addProductModal" title="Ürün Ekle" hide-footer size="lg" @show="getGames" @hide="hide">
      <b-form @submit="onSubmit">
        <div class="row">
          <div class="col-md-6">
            <b-form-group
                label="Ürün Adı">
              <b-input placeholder="" v-model="title"></b-input>
            </b-form-group>
            <b-form-group
                label="Ürün Fiyatı">
              <b-input v-model="price" type="number" step="0.10"></b-input>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group
                label="Mağazalar">
              <v-select
                  v-model="game_id"
                  class="form-control"
                  :options="games"
                  multiple
              ></v-select>
            </b-form-group>
            <b-form-group
                label="Ekstra Data">
              <b-input v-model="extra_data" ></b-input>
              <b-alert show="" variant="primary">
                Ürün satıldığında, ödeme onay adresinize (notify_url) bu bilgi gönderilecektir,
                EP miktarı, kredi tutarı 10, 20 vb. kullanılabilir.
              </b-alert>
            </b-form-group>
          </div>
          <div class="col-md-12">
            <b-form-group
                label="Ürün hakkında kısa bir açıklama:">
              <b-textarea v-model="description"></b-textarea>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group
                label="Ürün Stoklu mu satılsın?">
              <b-form-select v-model="api_stock" :options="options"></b-form-select>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group
                label="Stok Adeti (Stoklu ürün ise)">
              <b-input v-model="count"></b-input>
            </b-form-group>
          </div>
        </div>

        <div class="d-flex justify-content-center" v-if="!loading">
          <b-button type="submit" variant="success" class=""> <i class="fas fa-check" /> Oluştur </b-button>
        </div>
        <div class="d-flex justify-content-center" v-if="loading">
          <b-button type="submit" variant="success" class="" disabled> <span>  <i class="fas fa-spinner fa-spin"></i></span> </b-button>
        </div>
      </b-form>

    </b-modal>
  </div>
</template>

<script>
import apiService from "@/services/apiService";

export default {
  name: "AddProduct",
  data() {
    return {
      loading: false,
      title: '',
      description: '',
      price: 0,
      game_id: '',
      games: [],
      extra_data: '',
      api_stock: false,
      count: 0,
      options: [
        { value: false, text: 'Ürün Stoklu Satış Kapalı'},
        { value: true, text: 'Ürün Stoklu Satış Aktif'}
      ]
    }
  },
  methods: {
    hide() {
      this.games = []
      this.count = 0
      this.api_stock = false
    },
    getGames() {
      this.loading = false
      apiService.getGames()
          .then((res) => {
            if(res.state){
              res.data.map((game) => {
                if(game.status){
                  this.games.push({label: game.title, value: game.id})
                }
              })
            }
          })
    },
    errMsg(msg) {
      this.$swal({
        showConfirmButton: true,
        icon: "error",
        title: "Hata",
        text: msg,
        confirmButtonText: 'OK',
        confirmButtonColor: '#3085d6',
      });

    },
    successMsg(msg) {
      this.$swal({
        showConfirmButton: true,
        icon: "success",
        title: "Tebrikler!",
        text: msg,
        confirmButtonText: 'OK',
        confirmButtonColor: '#3085d6',
      });

    },
    onSubmit(event) {
      event.preventDefault()
      this.loading = true
      apiService.addProduct({api_stock: this.api_stock, count: this.count, title: this.title, description: this.description, extra_data: this.extra_data, price: this.price, game_id: this.game_id})
      .then((res) => {
        this.loading = false
        if(res.state){
          this.title = ''
          this.web_site = ''
          this.price = ''
          this.game_id = ''
          this.description  = ''
          this.successMsg('Ürün başarıyla eklendi.');
          this.$store.dispatch('getRefresh', {products: true})
          this.$bvModal.hide('addProductModal')

        }else{
          this.errMsg(res.message)
        }
      })
    }
  }
}
</script>

<style scoped>

</style>