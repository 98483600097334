<template>
  <div class="wrapper">
    <Menu></Menu>
    <div id="content-page" class="content-page">
      <div class="container-fluid">

        <div class="row">
          <div class="col-sm-12">
            <div class="row d-print-none">
              <div class="col-12">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title"> Ara </h4>
                    <form class="repeater" @submit.prevent="getProducts()">
                      <div >
                        <div class="row">
                          <div class="form-group col-sm-4">
                            <label >Ürün Adı</label>
                            <input
                                type="text"
                                v-model="product_name"
                                name="untyped-input"
                                class="form-control"
                            />
                          </div>
                          <div class="form-group col-lg-4">
                            <b-form-group
                                label="Mağaza"
                                label-for="input-1"
                            >
                              <v-select
                                  multiple
                                  v-model="game_id"
                                  class="form-control"
                                  :options="games"

                              ></v-select>
                            </b-form-group>
                          </div>

                          <div class="col-lg-2 align-self-center">
                            <b-button variant="info" type="submit"> <i class="fa fa-search" /> Ara </b-button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <br>
            <div class="iq-card">
              <div class="iq-card-header d-flex justify-content-between">
                <div class="iq-header-title">
                  <h4 class="card-title">Ürünlerim</h4>
                </div>
              </div>
              <div class="iq-card-body">
                <div class="row">
                  <div class="col-md-12">
                    <div class="button-group">
                      <b-button variant="success" v-b-modal.addProductModal>
                        <i class="fas fa-plus"></i> Yeni Ürün Ekle
                      </b-button>
                    </div>
                  </div>
                </div>

                <div class="table-responsive">
                  <b-table
                      striped
                      :items="tableData"
                      :fields="tableFields"
                      responsive="sm"
                      :busy="loading"
                      show-empty
                      class="table table-striped table-bordered mt-4"
                  >
                    <template v-slot:table-busy>
                      <div class="text-center text-primary my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Yükleniyor..</strong>
                      </div>
                    </template>
                    <template v-slot:empty="">
                      <b-alert show variant="primary">
                        Kayıt bulunamadı.
                      </b-alert>
                    </template>
                    <template v-slot:cell(game_id)="data">
                      {{data.item.game.title}}
                    </template>
                    <template v-slot:cell(sale_count)="data">
                      {{data.item.sale_count}}
                    </template>
                    <template v-slot:cell(count)="data">
                      <div v-if="data.item.api_stock">
                        <b-badge v-if="data.item.count > 0" variant="info">
                          {{ data.item.count }} Adet Kaldı.
                        </b-badge>
                        <b-badge  variant="danger" v-else>
                          Stok Bitti.
                        </b-badge>
                      </div>
                      <div v-else>
                        <b-badge variant="primary"> Sınırsız. </b-badge>
                      </div>
                    </template>
                    <template v-slot:cell(price)="data">
                      {{data.item.price | currency}} TL
                    </template>
                    <template v-slot:cell(gain)="data">
                      {{data.item.gain | currency}} TL
                    </template>
                    <template v-slot:cell(details)="data">
                      <b-button-group>
                        <b-button variant="success" @click="editProduct({id: data.item.id, game_id: data.item.game_id})"><i class="fas fa-edit"></i>  Düzenle</b-button>
                        <b-button variant="danger" @click="remove({id: data.item.id, game_id: data.item.game_id})"><i class="fas fa-remove"></i>  Sil</b-button>
                      </b-button-group>
                    </template>
                  </b-table>

                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <AddProduct></AddProduct>
    <EditProduct :id="editId" :game_id="editGameId"></EditProduct>
  </div>
</template>

<script>
import Menu from "@/components/Menu";
import apiService from "@/services/apiService";
import AddProduct from "@/components/Modals/AddProduct";
import EditProduct from "@/components/Modals/EditProduct";
export default {
  name: "Dashboard",
  data() {
    return {
      tableFields: [
        {key: 'id', label: '#', sortable: false },
        {key: 'game_id', label: 'Mağaza', sortable: true },
        {key: 'title', label: 'Ürün Adı', sortable: true },
        {key: 'price', label: 'Ürün Fiyatı', sortable: true },
        {key: 'sale_count', label: 'Satış Sayısı', sortable: true },
        {key: 'gain', label: 'Kazancınız', sortable: false },
        {key: 'extra_data', label: 'Ekstra Data', sortable: false },
        {key: 'count', label: 'Stok', sortable: false },
        {key: 'details', label: '#', sortable: false },
      ],
      loading: true,
      tableData: [],
      refresh: this.$store.getters['getRefresh'],
      editId: 0,
      editGameId: 0,
      product_name: '',
      game_id: '',
      games: [],
      totalRows: 0,
      currentPage: 1,
      perPage: 500,
    }
  },
  components: {
    Menu, AddProduct, EditProduct
  },
  methods: {
    editProduct(val) {
      this.editId = val.id
      this.editGameId = val.game_id
      this.$bvModal.show('editProductModal')
    },
    successMsg(msg) {
      this.$swal({
        showConfirmButton: true,
        icon: "success",
        title: "Tebrikler!",
        text: msg,
        confirmButtonText: 'OK',
        confirmButtonColor: '#3085d6',
      });

    },
    remove(data){
      this.$swal({
        title: "Ürün kalıcı olarak silinecektir, emin misiniz?",
        text: "",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: "Ürünü Sil",
        cancelButtonText: "Vazgeç"
      }).then((result) => {
        if(result.isConfirmed){
          apiService.removeProduct({id: data.id, game_id: data.game_id})
              .then((response) => {
                if(response.state){
                  this.successMsg('Ürün başarıyla Silindi.')
                  this.$store.dispatch('getRefresh', {products: true})
                }else{
                  this.errMsg(response.message)
                }
              })
        }
      })

    },
    getGames() {
      apiService.getGames()
          .then((res) => {
            if(res.state){
              res.data.map((game) => {
                if(game.status){
                  this.games.push({label: game.title, value: game.id})
                }
              })
            }
          })
    },
    errMsg(msg) {
      this.$swal({
        showConfirmButton: true,
        icon: "error",
        title: "Hata",
        text: msg,
        confirmButtonText: 'OK',
        confirmButtonColor: '#3085d6',
      });

    },
    getProducts(){
      this.loading = true
      apiService.getProducts({product_name: this.product_name, game_id: this.game_id})
      .then((res) => {
        this.loading = false
        if(res.state){
          this.tableData = res.data
        }else{
          this.errMsg(res.message)
        }
      })
    },

  },
  created() {
    this.getProducts()
    this.getGames()
  },
  watch: {
    refresh: function () {
      if(this.refresh.products){
        this.getProducts()
        this.$store.dispatch('getRefresh', {products:false})
      }
    },
    '$store.getters.getRefresh': function() {
      this.refresh = this.$store.getters.getRefresh
    }
  }
}
</script>

<style scoped>

</style>