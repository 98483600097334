<template>
  <div>
    <b-modal id="editStoreModal" title="Mağaza Düzenle" hide-footer size="lg" @show="detail">
      <b-alert show variant="primary">
        Mağazanız ile ilgili diğer bilgileri değiştirebilmek için iletişime geçmeniz gerekmektedir.
      </b-alert>
      <b-form @submit="onSubmit">
        <div class="row">
          <div class="col-md-12">
            <b-form-group
                label="Api İzinli İp Adresi">
              <b-input v-model="ip_address" placeholder="İzin için internet sitenizin IP adresini girin"></b-input>
            </b-form-group>
          </div>
          <div class="col-md-12">
            <b-form-group
                label="Notify URL:">
              <b-input v-model="notify_url" placeholder="Örneğin: http://siteadiniz.com/kasagame-notify.php"></b-input>
            </b-form-group>
          </div>
        </div>

        <div class="d-flex justify-content-center" v-if="!loading">
          <b-button type="submit" variant="success" class=""> <i class="fas fa-check" /> Kaydet </b-button>
        </div>
        <div class="d-flex justify-content-center" v-if="loading">
          <b-button type="submit" variant="success" class="" disabled> <span>  <i class="fas fa-spinner fa-spin"></i></span> </b-button>
        </div>
      </b-form>

    </b-modal>
  </div>
</template>

<script>
import apiService from "@/services/apiService";

export default {
  name: "EditStore",
  data() {
    return {
      loading: false,
      ip_address: 'Yükleniyor..',
      notify_url: 'Yükleniyor..',
    }
  },
  props: ['id'],
  methods: {
    errMsg(msg) {
      this.$swal({
        showConfirmButton: true,
        icon: "error",
        title: "Hata",
        text: msg,
        confirmButtonText: 'OK',
        confirmButtonColor: '#3085d6',
      });

    },
    successMsg(msg) {
      this.$swal({
        showConfirmButton: true,
        icon: "success",
        title: "Tebrikler!",
        text: msg,
        confirmButtonText: 'OK',
        confirmButtonColor: '#3085d6',
      });

    },
    onSubmit(event) {
      event.preventDefault()
      this.loading = true
      apiService.gameEdit({ ip_address: this.ip_address, notify_url: this.notify_url, id: this.id})
          .then((res) => {
            if(res.state){
              this.loading = false
              this.ip_address = ''
              this.notify_url = ''
              this.successMsg('Mağazanız başarıyla düzenlendi..');
              this.$store.dispatch('getRefresh', {stores: true})
              this.$bvModal.hide('editStoreModal')

            }else{
              this.errMsg(res.message)
            }
          })
    },
    detail() {
      this.loading = true
      if(this.id != 0){
        apiService.gameDetail({id: this.id})
            .then((res) => {
              this.loading = false
              if(res.state){
                this.notify_url = res.data[0].notify_url
                this.ip_address = res.data[0].api_ip
              }else{
                this.errMsg(res.message)
                this.$bvModal.hide('editStoreModal')
              }
            })
      }
    }
  },
  watch: {
    id: function () {
      this.detail()
    }
  }
}
</script>

<style scoped>

</style>