<template>
  <div class="wrapper">
    <Menu></Menu>
    <div id="content-page" class="content-page">
      <div class="container-fluid">

        <div class="row">
          <div class="col-sm-12">
            <div class="iq-card">
              <div class="iq-card-header d-flex justify-content-between">
                <div class="iq-header-title">
                  <h4 class="card-title">Kampanyalar</h4>
                </div>
              </div>
              <div class="col-sm-12">
                <b-alert show variant="primary">
                  Bu alanda müşterilerinize belirlediğiniz tarih aralığında, belirlediğiniz ürünlerinizde fiyat / kredi kampanyaları sunabilirsiniz.
                </b-alert>
              </div>
              <div class="iq-card-body">
                <div class="row">
                  <div class="col-md-12">
                    <div class="button-group">
                      <b-button to="/add-campaign" variant="success" >
                        <i class="fas fa-plus"></i> Yeni Kampanya Oluştur
                      </b-button>
                    </div>
                  </div>
                </div>

                <div class="table-responsive">
                  <b-table
                      striped
                      :items="tableData"
                      :fields="tableFields"
                      responsive="sm"
                      :busy="loading"
                      show-empty
                      class="table table-striped table-bordered mt-4"
                  >
                    <template v-slot:table-busy>
                      <div class="text-center text-primary my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Yükleniyor..</strong>
                      </div>
                    </template>
                    <template v-slot:empty="">
                      <b-alert show variant="primary">
                        Kayıt bulunamadı.
                      </b-alert>
                    </template>
                    <template v-slot:cell(game_id)="data">
                      {{data.item.game.title}}
                    </template>
                    <template v-slot:cell(products_count)="data">
                      {{data.item.products_count}} Adt. Ürün
                    </template>
                    <template v-slot:cell(start_date)="data">
                      {{data.item.start_date | formatDate}}
                    </template>
                    <template v-slot:cell(end_date)="data">
                      {{data.item.end_date | formatDate}}
                    </template>
                    <template v-slot:cell(details)="data">
                      <b-button-group>
                        <b-button variant="success" :to="{path: '/edit-campaign/'+data.item.id}"><i class="fas fa-edit"></i>  Düzenle</b-button>
                        <b-button variant="danger" @click="remove({id: data.item.id, game_id: data.item.game_id})"><i class="fas fa-remove"></i>  Sil</b-button>
                      </b-button-group>
                    </template>
                  </b-table>

                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Menu from "@/components/Menu";
import apiService from "@/services/apiService";
export default {
  name: "Campaigns",
  data() {
    return {
      tableFields: [
        {key: 'id', label: '#', sortable: false },
        {key: 'title', label: 'Kampanya Başlığı', sortable: true },
        {key: 'game_id', label: 'Mağaza', sortable: true },
        {key: 'products_count', label: 'Ürün Adeti', sortable: true },
        {key: 'start_date', label: 'Başlangıç Tarihi', sortable: true },
        {key: 'end_date', label: 'Bitiş Tarihi', sortable: true },
        {key: 'details', label: '#', sortable: false },
      ],
      loading: true,
      tableData: [],
      refresh: this.$store.getters['getRefresh'],
      editId: 0,
      editGameId: 0,
      product_name: '',
      game_id: '',
      games: [],
      totalRows: 0,
      currentPage: 1,
      perPage: 500,
    }
  },
  components: {
    Menu,
  },
  methods: {
    remove(data){
      this.$swal({
        title: "Kampanya kalıcı olarak silinecektir, emin misiniz?",
        text: "",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: "Kampanyayı Sil",
        cancelButtonText: "Vazgeç"
      }).then((result) => {
        if(result.isConfirmed){
          apiService.removeCampaign({id: data.id, game_id: data.game_id})
              .then((response) => {
                if(response.state){
                  this.successMsg('Kampanya başarıyla Silindi.')
                  this.getCampaigns()
                }else{
                  this.errMsg(response.message)
                }
              })
        }
      })

    },
    errMsg(msg) {
      this.$swal({
        showConfirmButton: true,
        icon: "error",
        title: "Hata",
        text: msg,
        confirmButtonText: 'OK',
        confirmButtonColor: '#3085d6',
      });

    },
    successMsg(msg) {
      this.$swal({
        showConfirmButton: true,
        icon: "success",
        title: "Tebrikler!",
        text: msg,
        confirmButtonText: 'OK',
        confirmButtonColor: '#3085d6',
      });

    },
    getCampaigns(){
      this.loading = true
      apiService.campaigns({})
      .then((res) => {
        this.loading = false
        if(res.state){
          this.tableData = res.data
        }else{
          this.errMsg(res.message)
        }
      })
    },
  },
  created() {
    this.getCampaigns()
  },
  watch: {
    refresh: function () {
      if(this.refresh.products){
        this.getProducts()
        this.$store.dispatch('getRefresh', {products:false})
      }
    },
    '$store.getters.getRefresh': function() {
      this.refresh = this.$store.getters.getRefresh
    }
  }
}
</script>

<style scoped>

</style>