<template>
  <div>
    <b-modal id="apiDetailsModal" title="Api Bilgileri" hide-footer size="lg" @show="getApiDetails">
      <b-alert show variant="primary">
        DİKKAT: Bu bilgiler sitenizin güvenliği için önemlidir, lütfen güvenmediğiniz kişiler ile paylaşmayın.
      </b-alert>
        <div class="row">
          <div class="col-md-12">
            <b-form-group
                label="Mağaza ID (merchant_id)">
              <b-input placeholder="" v-model="merchant_id" disabled></b-input>
            </b-form-group>
          </div>
          <div class="col-md-12">
            <b-form-group
                label="Api Key">
              <b-input  v-model="api_key" disabled></b-input>
            </b-form-group>
          </div>
          <div class="col-md-12">
              <div class="d-flex justify-content-center" v-if="!loading">
                <b-button-group>
                  <b-button @click="changeApi" variant="success" class=""> <i class="fas fa-refresh" /> Yeni Key Oluştur </b-button>
                  <b-button href="https://api.kasagame.com/" target="_blank" variant="danger" class=""> <i class="fas fa-download" /> API Dokümanlarını İNDİR </b-button>
                </b-button-group>
              </div>
              <div class="d-flex justify-content-center" v-if="loading">
                <b-button variant="success" class="" disabled> <span>  <i class="fas fa-spinner fa-spin"></i></span> </b-button>
              </div>


          </div>

        </div>

    </b-modal>
  </div>
</template>

<script>
import apiService from "@/services/apiService";

export default {
  name: "ApiDetails",
  props: ['id'],
  data() {
    return {
      api_key: 'Yükleniyor..',
      merchant_id: 'Yükleniyor..',
      loading: true
    }
  },
  methods: {
    errMsg(msg) {
      this.$swal({
        showConfirmButton: true,
        icon: "error",
        title: "Hata",
        text: msg,
        confirmButtonText: 'OK',
        confirmButtonColor: '#3085d6',
      });

    },
    successMsg(msg) {
      this.$swal({
        showConfirmButton: true,
        icon: "success",
        title: "Tebrikler!",
        text: msg,
        confirmButtonText: 'OK',
        confirmButtonColor: '#3085d6',
      });

    },
    getApiDetails() {
      this.api_key = 'Yükleniyor..'
      this.merchant_id = 'Yükleniyor..'
      this.loading = true
      if(this.id != 0){
        apiService.apiDetails({id: this.id})
            .then((res) => {
              this.loading = false
              if(res.state){
                this.api_key  = res.api_key
                this.merchant_id = res.merchant_id
              }else {
                this.errMsg(res.message)
                this.$bvModal.hide('apiDetailsModal')
              }
            })
      }
    },
    changeApi() {
      this.loading = true
      apiService.changeApi({id: this.id})
      .then((res) => {
        this.loading = false
        if(res.state){
          this.getApiDetails()
        }else{
          this.errMsg(res.message)
        }
      })
    }
  },
  watch:{
    id: function () {
      this.getApiDetails()
    }
  },

}
</script>

<style scoped>

</style>