<template>
  <div class="wrapper">
    <Menu></Menu>
    <div id="content-page" class="content-page">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12">
            <div class="row d-print-none">
              <div class="col-12">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title"> Ara </h4>
                    <form class="repeater" @submit.prevent="sales()">
                      <div >
                        <div class="row">
                          <div class="form-group col-lg-2">
                            <b-form-group
                                label="API return_id"
                            >
                              <b-input v-model="return_id"></b-input>
                            </b-form-group>
                          </div>
                          <div class="form-group col-lg-2">
                            <b-form-group
                                label="Ödeme Kanalı"
                                label-for="input-1"
                            >
                              <v-select
                                  multiple
                                  v-model="payment_id"
                                  class="form-control"
                                  :options="payment_channels"
                                  required
                              ></v-select>
                            </b-form-group>
                          </div>
                          <div class="form-group col-lg-2">
                            <b-form-group
                                label="Mağaza"
                                label-for="input-1"
                            >
                              <v-select
                                  multiple
                                  v-model="game_id"
                                  class="form-control"
                                  :options="games"
                                  type="text"
                              ></v-select>
                            </b-form-group>
                          </div>
                          <div class="form-group col-lg-3">
                            <b-form-group
                                label="Başlangıç Tarihi"
                                label-for="input-1"
                            >
                              <b-form-datepicker  v-model="startDate" class="mb-2"></b-form-datepicker>
                            </b-form-group>
                          </div>
                          <div class="form-group col-lg-3">
                            <b-form-group
                                label="Bitiş Tarihi"
                                label-for="input-1"
                            >
                              <b-form-datepicker  v-model="endDate" class="mb-2"></b-form-datepicker>
                            </b-form-group>
                          </div>

                          <div class="col-lg-2 align-self-center">
                            <b-button variant="info" type="submit"> <i class="fa fa-search" /> Ara </b-button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <br>
            <div class="col-sm-12" style="position: relative; z-index: -1;">
              <b-alert variant="primary" show="" v-if="!startDate & !endDate">
                Satışlar günlük olarak listelenmektedir.
              </b-alert>
            </div>
            <div class="iq-card iq-card-block iq-card-stretch iq-card-height" style="position: relative; z-index: -1;">
              <div class="iq-card-body">
                <div class="row">
                  <div class="col-md-6 col-lg-6">
                    <div class="d-flex align-items-center mb-3 mb-lg-0">
                      <div class="rounded-circle iq-card-icon iq-bg-primary mr-3"> <i class="fas fa-money"></i></div>
                      <div class="text-left">
                        <h4 v-if="loading"><b-skeleton></b-skeleton></h4>
                        <h4 v-else> {{ salePrice | currency }} TL</h4>
                        <p class="mb-0">Satış Tutarı</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-6">
                    <div class="d-flex align-items-center mb-3 mb-lg-0">
                      <div class="rounded-circle iq-card-icon iq-bg-info mr-3"> <i class="fas fa-money"></i></div>
                      <div class="text-left">
                        <h4 v-if="loading"><b-skeleton></b-skeleton></h4>
                        <h4 v-else> {{ gainPrice | currency }} TL</h4>
                        <p class="mb-0">Kazanç Tutarı</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
              <div class="iq-card">
              <div class="iq-card-header d-flex justify-content-between">
                <div class="iq-header-title">
                  <h4 class="card-title">Satışlar</h4>
                </div>
              </div>
              <div class="iq-card-body">

                <div class="table-responsive">
                  <b-table
                      striped
                      :items="tableData"
                      :fields="tableFields"
                      responsive="sm"
                      :busy="loading"
                      show-empty
                      class="table table-striped table-bordered mt-4"
                  >
                    <template v-slot:table-busy>
                      <div class="text-center text-primary my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Yükleniyor..</strong>
                      </div>
                    </template>
                    <template v-slot:empty="">
                        Kayıt bulunamadı.
                    </template>
                    <template v-slot:cell(api_payment_id)="data">
                      <div v-if="data.item.payment">
                        {{data.item.payment.title}}
                      </div>
                      <div v-else>
                        #Kasagame
                      </div>
                    </template>
                    <template v-slot:cell(game_id)="data">
                      {{data.item.game.title}}
                    </template>
                    <template v-slot:cell(product_id)="data">
                      {{data.item.product.title}}
                    </template>
                    <template v-slot:cell(price)="data">
                      {{data.item.price | currency}} TL
                    </template>
                    <template v-slot:cell(merchant_gain)="data">
                      {{data.item.merchant_gain | currency}} TL
                    </template>
                    <template v-slot:cell(sale_date)="data">
                      {{data.item.sale_date | formatDate}}
                    </template>
                    <template v-slot:cell(return_id)="data">
                      {{data.item.return_id}}
                      <div v-if="data.item.api">
                        <b-badge variant="success" v-if="data.item.response_data === 'OK'" @click="apiMessage(data.item.response_data)">
                          API Onaylandı.
                          <i class="fas fa-window"></i>
                        </b-badge>
                        <b-badge variant="danger" v-else @click="apiMessage(data.item.response_data)">
                          OK sonucu alınamadı.
                          <i class="fas fa-window"></i>
                        </b-badge>
                      </div>
                      <div v-else>
                        <b-badge variant="warning"> API kullanılmadı.  </b-badge>
                      </div>
                    </template>
                    <template v-slot:cell(details)="data">
                      <b-button-group>
                        {{data.item}}
                      </b-button-group>
                    </template>
                  </b-table>

                </div>

              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
    <ApiMessage :message="api_message"></ApiMessage>
  </div>
</template>

<script>
import Menu from "@/components/Menu";
import apiService from "@/services/apiService";
import ApiMessage from "@/components/Modals/ApiMessage";
export default {
  name: "Dashboard",
  data() {
    return {
      games: [],
      game_id: '',
      return_id: '',
      payment_channels: [],
      payment_id: '',
      startDate: '',
      endDate: '',
      tableFields: [
        {key: 'id', label: '#', sortable: false },
        {key: 'api_payment_id', label: 'Ödeme Kanalı', sortable: false },
        {key: 'game_id', label: 'Mağaza', sortable: false },
        {key: 'product_id', label: 'Ürün Adı', sortable: false },
        {key: 'price', label: 'Tutar', sortable: true },
        {key: 'merchant_gain', label: 'Kazanç', sortable: true },
        {key: 'return_id', label: 'Referans ID', sortable: false },
        {key: 'sale_date', label: 'Tarih', sortable: true },

      ],
      tableData: [],
      loading: false,
      salePrice: 0,
      gainPrice: 0,
      api_message: '',

    }
  },
  components: {
    Menu, ApiMessage
  },
  methods: {
    getGames() {
      apiService.getGames()
          .then((res) => {
            if(res.state){
              res.data.map((game) => {
                if(game.status){
                  this.games.push({label: game.title, value: game.id})
                }
              })
            }
          })
    },
    getPaymentChannels() {
      apiService.paymentChannels()
      .then((res) => {
        res.data.map((channel) => {
            this.payment_channels.push({label: channel.title, value: channel.id})
        })

      })
    },
    sales(){
      this.loading = true
      apiService.sales({return_id: this.return_id, game_id: this.game_id, payment_id : this.payment_id, startDate: this.startDate, endDate: this.endDate})
      .then((res) => {
        this.loading = false
        if(res.state){
          this.tableData = res.data
          let salePrice = 0
          let gainPrice = 0
          res.data.map((price) => {
            salePrice = parseFloat(salePrice) + parseFloat(price.price)
            gainPrice = parseFloat(gainPrice) + parseFloat(price.merchant_gain)
          })
          this.salePrice = salePrice
          this.gainPrice = gainPrice
        }else{
          this.errMsg(res.message)
        }
      })
    },
    errMsg(msg) {
      this.$swal({
        showConfirmButton: true,
        icon: "error",
        title: "Hata",
        text: msg,
        confirmButtonText: 'OK',
        confirmButtonColor: '#3085d6',
      });

    },
    successMsg(msg) {
      this.$swal({
        showConfirmButton: true,
        icon: "success",
        title: "Tebrikler!",
        text: msg,
        confirmButtonText: 'OK',
        confirmButtonColor: '#3085d6',
      });

    },
    apiMessage(message){
      this.api_message = message
      this.$bvModal.show('apiMessageModal')
    }
  },
  created() {
    this.getGames()
    this.getPaymentChannels()
    this.sales()
  },
  watch: {

  }
}
</script>

<style scoped>

</style>