<template>
<div>
  <center>Yönlendiriliyorsunuz... </center>
</div>
</template>

<script>
export default {
name: "Login",
  created() {
      this.$swal({
        showConfirmButton: true,
        icon: "error",
        title: "Hata",
        text: "Oturumunuz sonlandırılmış, tekrar yönlendiriliyorsunuz..",
        confirmButtonText: 'TAMAM',
        confirmButtonColor: '#3085d6',
      });
      setTimeout(() => {
        window.location.href  = 'https://kasagame.com/login'
      }, 2000)
  }
}
</script>

<style scoped>

</style>