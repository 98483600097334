<template>
  <div class="wrapper">
    <Menu></Menu>
    <div id="content-page" class="content-page">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12">
            <div class="row d-print-none">
              <div class="col-12">
                <div class="card">
                  <div class="card-body">
                    <h4 class="card-title"> Ara </h4>
                    <form class="repeater" @submit.prevent="sales()">
                      <div >
                        <div class="row">
                          <div class="form-group col-lg-4">
                            <b-form-group
                                label="İBAN"
                                label-for="input-1"
                            >
                              <b-input v-model="iban"></b-input>
                            </b-form-group>
                          </div>

                          <div class="form-group col-lg-3" >
                            <b-form-group
                                label="Başlangıç Tarihi"
                                label-for="input-1"
                            >
                              <b-form-datepicker  v-model="startDate" class="mb-2" ></b-form-datepicker>
                            </b-form-group>
                          </div>
                          <div class="form-group col-lg-3">
                            <b-form-group
                                label="Bitiş Tarihi"
                                label-for="input-1"
                            >
                              <b-form-datepicker  v-model="endDate" class="mb-2"></b-form-datepicker>
                            </b-form-group>
                          </div>

                          <div class="col-lg-2 align-self-center">
                            <b-button variant="info" type="submit"> <i class="fa fa-search" /> Ara </b-button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
            <br>
            <div class="col-sm-12" style="position: relative; z-index: -1;">
              <b-alert variant="primary" show="" v-if="!startDate & !endDate">
                Son 3 Aylık para çekme işlemleri listelenmiştir.
              </b-alert>
            </div>
            <div class="row" >
              <div class="col-md-6">
                <b-button v-b-modal.withdrawalModal class="btn btn-danger col-md-12 ">
                  <i class="fas fa-money"></i>
                  Para çek
                </b-button>
              </div>
            </div>
            <div class="row">

              <div class="col-md-6">
                <div class="iq-card iq-card-block iq-card-stretch iq-card-height" style="position: relative; z-index: -1;">
                  <div class="iq-card-body">
                    <div class="row">
                      <div class="col-md-6 col-lg-6">

                        <div class="d-flex align-items-center mb-3 mb-lg-0">
                          <div class="rounded-circle iq-card-icon iq-bg-danger mr-3"> <i class="fas fa-wallet"></i></div>
                          <div class="text-left">
                            <h4 v-if="loading"><b-skeleton></b-skeleton></h4>
                            <h4 v-else> {{ userBalance }} TL</h4>
                            <p class="mb-0">Güncel Bakiyeniz</p>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="iq-card iq-card-block iq-card-stretch iq-card-height" style="position: relative; z-index: -1;">
                  <div class="iq-card-body">
                    <div class="row">
                      <div class="col-md-6 col-lg-6">
                        <div class="d-flex align-items-center mb-3 mb-lg-0">
                          <div class="rounded-circle iq-card-icon iq-bg-success mr-3"> <i class="fas fa-bank"></i></div>
                          <div class="text-left">
                            <h4 v-if="loading"><b-skeleton></b-skeleton></h4>
                            <h4 v-else> {{ totalAmount | currency }} TL</h4>
                            <p class="mb-0">Çekilen Toplam Tutar</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <br>
              <div class="iq-card">
              <div class="iq-card-header d-flex justify-content-between">
                <div class="iq-header-title">
                  <h4 class="card-title">Para Çekimleri</h4>
                </div>
              </div>
              <div class="iq-card-body">

                <div class="table-responsive">
                  <b-table
                      striped
                      :items="tableData"
                      :fields="tableFields"
                      responsive="sm"
                      :busy="loading"
                      show-empty
                      class="table table-striped table-bordered mt-4"
                  >
                    <template v-slot:table-busy>
                      <div class="text-center text-primary my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Yükleniyor..</strong>
                      </div>
                    </template>
                    <template v-slot:empty="">
                        Kayıt bulunamadı.
                    </template>
                    <template v-slot:cell(amount)="data">
                      <strong>{{data.item.amount | currency}} TL</strong>
                    </template>
                    <template v-slot:cell(date)="data">
                      {{data.item.date | formatDate}}
                    </template>
                    <template v-slot:cell(status)="data">
                      <b-badge variant="primary" v-if="data.item.status === '0'">
                        İnceleniyor
                      </b-badge>
                      <b-badge variant="success"  v-if="data.item.status === '1'">
                        Ödeme Aktarıldı.
                      </b-badge>
                      <b-badge variant="danger"  v-if="data.item.status === '2'">
                        İade edildi.
                      </b-badge>
                    </template>
                  </b-table>

                </div>

              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
    <withdrawal></withdrawal>
  </div>
</template>

<script>
import Menu from "@/components/Menu";
import apiService from "@/services/apiService";
import Withdrawal from "@/components/Modals/Withdrawal";
import {store} from "@/store";
export default {
  name: "Balance",
  data() {
    return {
      startDate: '',
      endDate: '',
      userBalance: this.$store.getters.user.user.earning_balance,
      tableFields: [
        {key: 'id', label: '#', sortable: false },
        {key: 'amount', label: 'Tutar', sortable: false },
        {key: 'bank_detail', label: 'Banka Bilgileri', sortable: false },
        {key: 'status', label: 'Durum', sortable: false },
        {key: 'date', label: 'Tarih', sortable: true },

      ],
      tableData: [],
      loading: false,
      totalAmount: 0,
      iban: '',
      refresh: this.$store.getters['getRefresh'],

    }
  },
  components: {
    Menu, Withdrawal
  },
  methods: {
    getBalance() {
      apiService.getToken()
          .then((res) => {
            if(res.state){
              store.dispatch('updateUser', {user: res.user, token: res.token})
              this.userBalance = res.user.earning_balance
            }else{
              store.dispatch('updateUser', {token: ""})
              this.$router.push({path: '/login'})
            }
          })
    },
    sales(){
      this.loading = true
      apiService.withdrawnList({iban: this.iban, startDate: this.startDate, endDate: this.endDate})
      .then((res) => {
        this.loading = false
        if(res.state){
          this.tableData = res.data
          let totalAmount = 0
          res.data.map((item) => {
            if(item.status === '1'){
              totalAmount = parseFloat(item.amount) + parseFloat(totalAmount)
            }
          })
          this.totalAmount = totalAmount
        }else{
          this.errMsg(res.message)
        }
      })
    },
    errMsg(msg) {
      this.$swal({
        showConfirmButton: true,
        icon: "error",
        title: "Hata",
        text: msg,
        confirmButtonText: 'OK',
        confirmButtonColor: '#3085d6',
      });

    },
    successMsg(msg) {
      this.$swal({
        showConfirmButton: true,
        icon: "success",
        title: "Tebrikler!",
        text: msg,
        confirmButtonText: 'OK',
        confirmButtonColor: '#3085d6',
      });

    },

  },
  created() {
    this.sales()
    this.getBalance()
  },
  watch: {
    refresh: function () {
      if(this.refresh.balance){
        this.sales()
        this.getBalance()
        this.$store.dispatch('getRefresh', {balance:false})
      }
    },
    '$store.getters.getRefresh': function() {
      this.refresh = this.$store.getters.getRefresh
    }

  }
}
</script>

<style scoped>

</style>