<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  data() {
    return {
      token: this.$route.query.token
    }
  },
  methods: {
  },
  mounted() {

  }
}
</script>

<style >
@import url('./assets/css/style.css');
</style>
