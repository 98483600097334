<template>
  <div>
    <b-modal id="apiMessageModal" title="Api Bilgileri" hide-footer size="lg" @show="getApiDetails">
      <b-alert show variant="primary">
        APİ SONUÇ PENCERESİ
      </b-alert>
        <div class="row">
          <div class="col-md-12">
            <b-form-textarea v-model="message"> </b-form-textarea>
          </div>

        </div>

    </b-modal>
  </div>
</template>

<script>

export default {
  name: "ApiDetails",
  props: ['message'],
  data() {
    return {
    }
  },

}
</script>

<style scoped>

</style>