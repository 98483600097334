import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueSweetalert2 from 'vue-sweetalert2';
import moment from 'moment'
import router from '@/router';
import {store} from '@/store'
import vSelect from 'vue-select'



Vue.filter("currency",(value) => {
  return parseFloat(value).toLocaleString(undefined, {minimumFractionDigits : 2})
});
Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('DD.MM.YYYY HH:mm')
  }
})

// style
import('./assets/css/responsive.css');
import('./assets/css/typography.css');
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
require('./assets/css/fontawesome.min.css')
import 'sweetalert2/dist/sweetalert2.min.css';
import "vue-select/dist/vue-select.css";

Vue.config.productionTip = false
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueSweetalert2)
Vue.component('v-select', vSelect)

new Vue({
  render: h => h(App),
  router,
  store,
}).$mount('#app')
