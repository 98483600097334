<template>
  <div>
    <b-modal id="withdrawalModal" title="Para Çek" hide-footer size="lg" @show="getBalance">
      <b-form @submit="onSubmit">
        <div class="row">
          <div class="col-md-6">
            <b-form-group
                label="Bakiyeniz:">
              <b-input placeholder="" :value="balance + ' TL'" disabled></b-input>
            </b-form-group>
          </div>
          <div class="col-md-6 balance-try">
            <b-form-group label="Çekim Tutarı:">
              <div class="input-group">
                <input v-model="amount" type="number" min="0" class="form-control" placeholder="Tutar" >
                <div class="input-group-append">
                  <span class="input-group-text" id="basic-addon2">TL</span>
                </div>
              </div>
            </b-form-group>
          </div>
        </div>
        <b-form-group
            label="Banka Bilgileriniz:">
          <b-textarea v-model="bank_detail" disabled></b-textarea>
        </b-form-group>

        <div class="d-flex justify-content-center" v-if="!loading">
          <b-button type="submit" variant="success" class=""> <i class="fas fa-check" /> Kaydet </b-button>
        </div>
        <div class="d-flex justify-content-center" v-if="loading">
          <b-button type="submit" variant="success" class="" disabled> <span>  <i class="fas fa-spinner fa-spin"></i></span> </b-button>
        </div>
      </b-form>

    </b-modal>
  </div>
</template>

<script>
import apiService from "@/services/apiService";
import {store} from "@/store";

export default {
  name: "WithdrawalModal",
  data() {
    return {
      loading: false,
      balance: this.$store.getters.user.user.earning_balance,
      amount: 0,
      bank_detail: this.$store.getters.user.user.bank_details.bank_name+' - ' + this.$store.getters.user.user.bank_details.iban
    }
  },
  methods: {
    getBalance() {
      apiService.getToken()
          .then((res) => {
            if(res.state){
              store.dispatch('updateUser', {user: res.user, token: res.token})
              this.balance = res.user.earning_balance
            }else{
              store.dispatch('updateUser', {token: ""})
              this.$router.push({path: '/login'})
            }
          })
    },
    errMsg(msg) {
      this.$swal({
        showConfirmButton: true,
        icon: "error",
        title: "Hata",
        text: msg,
        confirmButtonText: 'OK',
        confirmButtonColor: '#3085d6',
      });

    },
    successMsg(msg) {
      this.$swal({
        showConfirmButton: true,
        icon: "success",
        title: "Tebrikler!",
        text: msg,
        confirmButtonText: 'OK',
        confirmButtonColor: '#3085d6',
      });

    },
    onSubmit(event) {
      event.preventDefault()
      this.loading = true
      apiService.withdrawal({amount: this.amount})
      .then((res) => {
        this.loading = false
        if(res.state){
          this.title = ''
          this.web_site = ''
          this.price = ''
          this.game_id = ''
          this.description  = ''
          this.successMsg('Para Çekim talebi oluşturuldu.');
          this.$store.dispatch('getRefresh', {balance: true})
          this.$bvModal.hide('withdrawalModal')
        }else{
          this.errMsg(res.message)
        }
      })
    }
  }
}
</script>

<style scoped>

</style>