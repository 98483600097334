<template>
<div>
  Loading..
</div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      token: this.$route.query.token
    }
  },
  created() {
    if(!this.$store.getters.user.token){
      if(!this.token){
        this.$router.push({path: '/login'})
      }else{
        this.$store.dispatch('updateUser', {token: this.token})
        setTimeout(() => {
          this.$router.push({path: '/dashboard'})
        }, 500)
      }
    }else{
      if(!this.token){
        setTimeout(() => {
          this.$router.push({path: '/dashboard'})
        }, 500)
      }else{
        this.$store.dispatch('updateUser', {token: this.token})
        setTimeout(() => {
          this.$router.push({path: '/dashboard'})
        }, 500)
      }

    }
  }
}
</script>

<style scoped>

</style>