<template>
  <div>
    <b-modal id="addStoreModal" title="Mağaza Ekle" hide-footer size="lg" @show="loading = false">
      <b-alert show variant="primary">
        Mağazanızın onaylanabilmesi için istenen bilgileri lütfen eksiksiz doldurun
      </b-alert>
      <b-alert show variant="danger">
        DİKKAT: Sahte başvuru işlemlerinde hesabınız askıya alınabilir.
      </b-alert>
      <b-form @submit="onSubmit">
        <div class="row">
          <div class="col-md-6">
            <b-form-group
                label="Mağaza Adı">
              <b-input placeholder="" v-model="title"></b-input>
            </b-form-group>
            <b-form-group
                label="Api İzinli İp Adresi">
              <b-input v-model="ip_address" placeholder="İzin için internet sitenizin IP adresini girin"></b-input>
            </b-form-group>
          </div>
          <div class="col-md-6">
            <b-form-group
                label="Mağaza Web Sitesi">
              <b-input  v-model="web_site" placeholder="http://siteniz.com"></b-input>
            </b-form-group>
            <b-form-group
                label="Notify URL:">
              <b-input v-model="notify_url" placeholder="Örneğin: http://siteadiniz.com/kasagame-notify.php"></b-input>
            </b-form-group>
          </div>
        </div>
        <b-form-group
            label="Siteniz hakkında kısa bir açıklama:">
          <b-textarea v-model="description"></b-textarea>
        </b-form-group>

        <div class="d-flex justify-content-center" v-if="!loading">
          <b-button type="submit" variant="success" class=""> <i class="fas fa-check" /> Oluştur </b-button>
        </div>
        <div class="d-flex justify-content-center" v-if="loading">
          <b-button type="submit" variant="success" class="" disabled> <span>  <i class="fas fa-spinner fa-spin"></i></span> </b-button>
        </div>
      </b-form>

    </b-modal>
  </div>
</template>

<script>
import apiService from "@/services/apiService";

export default {
  name: "AddStore",
  data() {
    return {
      loading: false,
      title: '',
      web_site: '',
      ip_address: '',
      notify_url: '',
      description: ''
    }
  },
  methods: {
    errMsg(msg) {
      this.$swal({
        showConfirmButton: true,
        icon: "error",
        title: "Hata",
        text: msg,
        confirmButtonText: 'OK',
        confirmButtonColor: '#3085d6',
      });

    },
    successMsg(msg) {
      this.$swal({
        showConfirmButton: true,
        icon: "success",
        title: "Tebrikler!",
        text: msg,
        confirmButtonText: 'OK',
        confirmButtonColor: '#3085d6',
      });

    },
    onSubmit(event) {
      event.preventDefault()
      this.loading = true
      apiService.addGame({title: this.title, web_site: this.web_site, ip_address: this.ip_address, notify_url: this.notify_url, description: this.description})
      .then((res) => {
        this.loading = false
        if(res.state){
          this.title = ''
          this.web_site = ''
          this.ip_address = ''
          this.notify_url = ''
          this.description  = ''
          this.successMsg('Mağazanız başarıyla oluşturuldu.');
          this.$store.dispatch('getRefresh', {stores: true})
          this.$bvModal.hide('addStoreModal')

        }else{
          this.errMsg(res.message)
        }
      })
    }
  }
}
</script>

<style scoped>

</style>