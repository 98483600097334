import Vue from 'vue';
import VueRouter from 'vue-router';
import {store} from '@/store';
import apiService from "@/services/apiService";

//pages
import Dashboard from "@/views/Dashboard";
import Stores from "@/views/Stores";
import Login from "@/views/Login";
import Home from "@/views/Home";
import Products from "@/views/Products";
import Reports from "@/views/Reports";
import Balance from "@/views/Balance";
import Campaigns from "@/views/Campaigns";
import AddCampaign from "@/views/AddCampaign";
import EditCampaign from "@/views/EditCampaign";
import logout from "@/views/logout";

Vue.use(VueRouter);
const routes = [
    {
      path: '/',
      name: 'Home',
      component: Home
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        component: Dashboard,
        meta: {requiresLogin: true}
    },
    {
        path: '/stores',
        name: 'Stores',
        component: Stores,
        meta: {requiresLogin: true}
    },
    {
        path: '/products',
        name: 'Products',
        component: Products,
        meta: {requiresLogin: true}
    },
    {
        path: '/reports',
        name: 'Reports',
        component: Reports,
        meta: {requiresLogin: true}
    },
    {
        path: '/balance',
        name: 'Balance',
        component: Balance,
        meta: {requiresLogin: true}
    },
    {
        path: '/campaigns',
        name: 'Campaigns',
        component: Campaigns,
        meta: {requiresLogin: true}
    },
    {
        path: '/add-campaign',
        name: 'AddCampaign',
        component: AddCampaign,
        meta: {requiresLogin: true}
    },
    {
        path: '/edit-campaign/:id',
        name: 'EditCampaign',
        component: EditCampaign,
        meta: {requiresLogin: true}
    },
    {
        path: '/logout',
        name: 'logout',
        component: logout,
        meta: {requiresLogin: true}
    },
    {
        path: '/login',
        name: 'Login',
        component: Login
    }

]
const router = new VueRouter({
    mode: 'hash',
    routes,
    scrollBehavior() {
        return {x: 0, y: 0}
    }
});

router.beforeEach((to, from, next) => {
    if (to.meta.requiresLogin) {
        if(store.getters.user.token) {
            const response = apiService.getToken()
            response.then((result) => {
                if(!result.state){
                    store.dispatch('updateUser', {token: ""})
                    next({path: '/login'})
                }else {
                    store.dispatch('updateUser', {user: result.user, token: result.token})
                    next()
                }
            })
        }
        else {
            next({path: '/login'})
        }
    }
    else {
        next()
    }
})
export default router
