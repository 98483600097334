const axios = require('axios');
import { store } from '@/store'
let apiUrl = ''
if (process.env.NODE_ENV === 'production') {
    apiUrl = 'https://api.kasagame.com'
} else {
    apiUrl = 'http://localhost:3000'

}

export default {
    getToken() {
        axios.defaults.headers.common['x-access-token'] = `${store.getters.user.token}`
        return axios.get(apiUrl+'/user/getToken')
            .then(response => response.data)
    },
    logout() {
        axios.defaults.headers.common['x-access-token'] = `${store.getters.user.token}`
        return axios
            .get(apiUrl+'/user/logout')
            .then((response) => {
                return response
            })
    },

    getStatics(){
        axios.defaults.headers.common['x-access-token'] = `${store.getters.user.token}`
        return axios.post(apiUrl+'/merchant/statics')
            .then(response => response.data)
    },
    getGames(){
        axios.defaults.headers.common['x-access-token'] = `${store.getters.user.token}`
        return axios.post(apiUrl+'/merchant/games')
            .then(response => response.data)
    },
    addGame(data){
        axios.defaults.headers.common['x-access-token'] = `${store.getters.user.token}`
        return axios.post(apiUrl+'/merchant/add-game', data)
            .then(response => response.data)
    },
    apiDetails(data){
        axios.defaults.headers.common['x-access-token'] = `${store.getters.user.token}`
        return axios.post(apiUrl+'/merchant/api-details', data)
            .then(response => response.data)
    },
    changeApi(data){
        axios.defaults.headers.common['x-access-token'] = `${store.getters.user.token}`
        return axios.post(apiUrl+'/merchant/change-api-details', data)
            .then(response => response.data)
    },
    gameDetail(data){
        axios.defaults.headers.common['x-access-token'] = `${store.getters.user.token}`
        return axios.post(apiUrl+'/merchant/game-detail', data)
            .then(response => response.data)
    },
    gameEdit(data){
        axios.defaults.headers.common['x-access-token'] = `${store.getters.user.token}`
        return axios.post(apiUrl+'/merchant/game-edit', data)
            .then(response => response.data)
    },
    getProducts(data) {
        axios.defaults.headers.common['x-access-token'] = `${store.getters.user.token}`
        return axios.post(apiUrl+'/merchant/products', data)
            .then(response => response.data)
    },
    addProduct(data) {
        axios.defaults.headers.common['x-access-token'] = `${store.getters.user.token}`
        return axios.post(apiUrl+'/merchant/add-product', data)
            .then(response => response.data)
    },
    removeProduct(data){
        axios.defaults.headers.common['x-access-token'] = `${store.getters.user.token}`
        return axios.post(apiUrl+'/merchant/remove-product', data)
            .then(response => response.data)

    },
    productDetail(data){
        axios.defaults.headers.common['x-access-token'] = `${store.getters.user.token}`
        return axios.post(apiUrl+'/merchant/product-detail', data)
            .then(response => response.data)

    },
    editProduct(data) {
        axios.defaults.headers.common['x-access-token'] = `${store.getters.user.token}`
        return axios.post(apiUrl+'/merchant/edit-product', data)
            .then(response => response.data)
    },
    paymentChannels(data) {
        axios.defaults.headers.common['x-access-token'] = `${store.getters.user.token}`
        return axios.post(apiUrl+'/merchant/payment-channels', data)
            .then(response => response.data)
    },
    sales(data) {
        axios.defaults.headers.common['x-access-token'] = `${store.getters.user.token}`
        return axios.post(apiUrl+'/merchant/sales', data)
            .then(response => response.data)
    },
    withdrawnList(data){
        axios.defaults.headers.common['x-access-token'] = `${store.getters.user.token}`
        return axios.post(apiUrl+'/merchant/withdrawns', data)
            .then(response => response.data)
    },
    withdrawal(data) {
        return axios.post(apiUrl+ '/payments/withdrawal', data)
            .then(response => response.data)
    },
    campaigns(data) {
        axios.defaults.headers.common['x-access-token'] = `${store.getters.user.token}`
        return axios.post(apiUrl+'/merchant/campaigns', data)
            .then(response => response.data)
    },
    campaignDetail(data) {
        axios.defaults.headers.common['x-access-token'] = `${store.getters.user.token}`
        return axios.post(apiUrl+'/merchant/campaign-detail', data)
            .then(response => response.data)
    },
    removeCampaign(data) {
        axios.defaults.headers.common['x-access-token'] = `${store.getters.user.token}`
        return axios.post(apiUrl+'/merchant/remove-campaign', data)
            .then(response => response.data)
    },
    addCampaign(data) {
        axios.defaults.headers.common['x-access-token'] = `${store.getters.user.token}`
        return axios.post(apiUrl+'/merchant/add-campaign', data)
            .then(response => response.data)
    },
    editCampaign(data) {
        axios.defaults.headers.common['x-access-token'] = `${store.getters.user.token}`
        return axios.post(apiUrl+'/merchant/edit-campaign', data)
            .then(response => response.data)
    },
    editchatId(data) {
        axios.defaults.headers.common['x-access-token'] = `${store.getters.user.token}`
        return axios.post(apiUrl+'/merchant/chat-id', data)
            .then(response => response.data)
    },

}