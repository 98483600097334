<template>
<div>
  loading..
</div>
</template>

<script>
import apiService from "@/services/apiService";

export default {
  name: "logout",
  methods: {
    logout(){
      apiService.logout()
      .then(() => {
        this.$router.push('/')
      })
    }
  },
  created() {
    this.logout()
  }
}
</script>

<style scoped>

</style>