<template>
  <div class="wrapper">
    <Menu></Menu>
    <div id="content-page" class="content-page">
      <div class="container-fluid">
        <div class="row">
          <div class="col-sm-12">
            <div class="iq-card">
              <div class="iq-card-header d-flex justify-content-between">
                <div class="iq-header-title">
                  <h4 class="card-title">Mağazalarım</h4>
                </div>
              </div>
              <div class="iq-card-body">
                <div class="row">
                  <div class="col-md-12">
                    <div class="button-group">
                      <b-button variant="success" v-b-modal.addStoreModal>
                        <i class="fas fa-plus"></i> Yeni Mağaza Oluştur
                      </b-button>
                    </div>
                  </div>
                </div>
                <div class="table-responsive">
                  <b-table
                      striped
                      :items="tableData"
                      :fields="tableFields"
                      responsive="sm"
                      :busy="loading"
                      show-empty
                      class="table table-striped table-bordered mt-4"
                  >
                    <template v-slot:table-busy>
                      <div class="text-center text-primary my-2">
                        <b-spinner class="align-middle"></b-spinner>
                        <strong>Yükleniyor..</strong>
                      </div>
                    </template>
                    <template v-slot:empty="">
                      <b-alert show variant="primary">
                        Kayıt bulunamadı.
                      </b-alert>
                    </template>
                    <template v-slot:cell(sales_count)="data">
                      {{data.item.sales_count}}
                    </template>
                    <template v-slot:cell(status)="data">
                      <b-badge variant="success" v-if="data.item.status">
                        Satışlar aktif
                      </b-badge>
                      <b-badge variant="primary" v-if="!data.item.status">
                        Mağaza Onayı bekleniyor.
                      </b-badge>
                    </template>
                    <template v-slot:cell(details)="data">
                      <b-button-group>
                        <b-button variant="primary" @click="apiDetails(data.item.id)"><i class="fas fa-code"></i> API Bilgileri</b-button>
                        <b-button variant="success" @click="editStore(data.item.id)"><i class="fas fa-edit"></i>  Düzenle</b-button>
                      </b-button-group>
                    </template>
                  </b-table>

                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <AddStore></AddStore>
    <ApiDetails :id="editId"></ApiDetails>
    <EditStore :id="editId"></EditStore>
  </div>
</template>

<script>
import Menu from "@/components/Menu";
import apiService from "@/services/apiService";
import AddStore from "@/components/Modals/AddStore";
import ApiDetails from "@/components/Modals/ApiDetails";
import EditStore from "@/components/Modals/EditStore";
export default {
  name: "Dashboard",
  data() {
    return {
      tableFields: [
        {key: 'id', label: '#', sortable: false },
        {key: 'title', label: 'Mağaza Başlığı', sortable: true },
        {key: 'web_site', label: 'Web Site', sortable: true },
        {key: 'sales_count', label: 'Satış Sayısı', sortable: true },
        {key: 'notify_url', label: 'Geri Bildirim Syf.', sortable: false },
        {key: 'api_ip', label: 'İp Adres', sortable: false },
        {key: 'status', label: 'Durum', sortable: false },
        {key: 'details', label: '#', sortable: false },
      ],
      loading: true,
      tableData: [],
      refresh: this.$store.getters['getRefresh'],
      editId: 0
    }
  },
  components: {
    Menu, AddStore, ApiDetails, EditStore
  },
  methods: {
    apiDetails(val) {
      this.editId = val
      this.$bvModal.show('apiDetailsModal')
    },
    editStore(val) {
      this.editId = val
      this.$bvModal.show('editStoreModal')
    },
    getGames(){
      this.loading = true
      apiService.getGames()
      .then((res) => {
        if(res.state){
          this.tableData = res.data
          this.loading = false
        }
      })
    }
  },
  created() {
    this.getGames()
  },
  watch: {
    refresh: function () {
      if(this.refresh.stores){
        this.getGames()
        this.$store.dispatch('getRefresh', {stores:false})
      }
    },
    '$store.getters.getRefresh': function() {
      this.refresh = this.$store.getters.getRefresh
    }

  }
}
</script>

<style scoped>

</style>